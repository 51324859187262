export default {
    zh: {
        "outerContainerTitle": "抽样(使用最新发布的512bits随机数)",
        "setting": "参数设置",
        "startRangeTips": "请输入起始数(1-256)",
        "endRangeTips": "请输入结束数(1-256)",
        "selectionTips": "请输入要选择的数的数量(1-64)",
        "randomButtonText": "生成抽样结果",
        "copyButtonText": "复制",
        "downloadButtonText": "下载",
        "inputIllegalTips": "输入的数值不合法",
        "copySuccessTips": "复制成功",
        "downloadFileName": "抽样结果.txt",
        "nextRandomCountdownTitle": "下一轮随机数更新计时:",
        "downloadRandomNumberTitle": "量子随机数下载",
        "acquireTitle": "抽样",
        "downloadTitle": "量子随机数下载",
        "downloadTips": "本平台为注册用户提供量子随机数下载服务，注册用户每次下载一个大小为10 MB的量子随机数文件，每天可下载10次(剩余次数：{downloadCountRemain}次)",
        "loginTips": '请先登录',
        "noHistoryTips": "您还没有历史下载记录",
        "historyListTitle": "随机数下载时间",
        "randomGenerateTime": "随机数生成时间",
        "loadingText": "加载中..."
    },
    en: {
        "outerContainerTitle": "Sampling(Using the latest 512bits random number)",
        "setting": "Parameter Settings",
        "startRangeTips": "Please enter the starting number (1-256)",
        "endRangeTips": "Please enter the end number (1-256)",
        "selectionTips": "Please enter the number of numbers to be selected (1-64)",
        "randomButtonText": "Generate",
        "copyButtonText": "Copy",
        "downloadButtonText": "Download",
        "inputIllegalTips": "The input value is illegal",
        "copySuccessTips": "Copy successfully",
        "downloadFileName": "generate result.txt",
        "nextRandomCountdownTitle": "Time until next random numbers:",
        "downloadRandomNumberTitle": "Quantum Random Number Download",
        "acquireTitle": "Sampling",
        "downloadTitle": "QRN download",
        "downloadTips": "The platform provides download services of quantum random numbers for registered users. Registered users can download a quantum random number file with a size of 10 MB each time,  with a maximum of 10 times per day (remaining: {downloadCountRemain} times)",
        'loginTips': 'Please login first',
        "noHistoryTips": "You don't have any download history",
        "historyListTitle": "Download time",
        "randomGenerateTime": "Generation time",
        "loadingText": "Loading..."
    }
}