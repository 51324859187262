export default {
    zh: {
        'history': '历史',
        '1935': 'Albert Einstein等人提出EPR佯谬，从定域实在论出发认为量子力学是不完备的',
        '1964': 'John Bell提出可以通过检验贝尔不等式是否被破坏判断是否存在局域隐变量',
        '1991': 'Dominic Mayers与姚期智提出“自检测”理论',
        '2007': 'Roger Colbeck在其博士论文中提出利用不可信设备实现随机数生成和拓展的协议',
        '2010': 'Stefano Pironio等人首次完成基于贝尔不等式验证的设备无关量子随机数生成实验',
        '2015': 'B. Hensen等人首次实现了无漏洞贝尔不等式的检验',
        '2018': '刘洋、张强等人在实验中在关闭探测漏洞的同时，实现高速的设备无关量子随机数生成',
        '2018a': 'P. Bierhorst等人在实验中实现了抵御经典攻击的设备无关量子随机数生成实验',
        '2018b': '刘洋、张强等人在实验中实现了抵御量子攻击的设备无关量子随机数生成方案',
        "cooperation": '合作与交流',
        "achievements": '本随机数产生平台已产生以下学术成果',
        "achievements_1": '1、2018年，首次实验实现抵御经典和量子攻击的设备无关量子随机数生成',
        "achievements_2": '2、2018年，参与大贝尔实验，利用人类自由意志生成的随机数测试贝尔不等式',
        "achievements_3": '3、2018年，在关闭探测漏洞和局域漏洞的同时，利用星光随机数测试贝尔不等式',
        "achievements_4": '4、2018年，在关闭探测漏洞的同时，实现高速的设备无关量子随机数生成',
        "achievements_5": '5、2019年，实验实现设备无关量子随机数拓展',
        "achievements_6": '6、2019年，实验实现抵御量子边信息的设备无关量子随机数拓展',
        "historyContent1": ' 1、2018年刘洋代表项目组参加Qcrypt2018会议并做题为”Device-independentquantum random number generation” 的报告。',
        "historyContent2": ' 2、2019年刘文钊代表项目组参加全国物理秋季年会并做题为“设备无关量子随机数扩展“ 的报告。',
        "historyContent3": ' 3、2019年刘文钊代表项目组参加Single Photon Workshop2019并做题为“Device independent randomness expansion against quantum side information”的报告。',
        "historyContent4": ' 4、2019年刘文钊代表项目组参加Qcypt2020会议并作题为 ”Device-independentrandomness expansion against quantum side information” 的报告。',
        "historyContent5": ' 5、2020年10月16日，项目组在上海举行随机数信标及其应用讨论会。',
        "historyContent6": ' 6、2021年1月22日，项目组参加深圳南方科技大学第一届《量子随机数及其应用》会议。',

    },
    en: {
        'history': 'History',
        '1935': 'Albert Einstein et al proposed the EPR paradox, which believed that quantum mechanics was incomplete from the perspective of localized realism theory',
        '1964': 'John Bell proposed that the existence of local hidden variables could be verified by testing whether the Bell inequality was broken',
        '1991': 'Dominic Mayers and Andrew Yao proposed the theory of “Self-testing”',
        '2007': 'Roger Colbeck proposed a protocol for random number generation and expansion using untrusted devices in his PhD thesis',
        '2010': 'Stefano Pironio et al. completed the first device-independent quantum random number generation experiment verified by Bell theorem',
        '2015': 'B. Hensen et al. performed the first experiment of the loop-free Bell inequality test',
        '2018': 'Liu Yang, Zhang Qiang et al. realized high-speed device-independent quantum random number generation without a detection loophole',
        '2018a': 'P. Bierhorst et al. experimentally implemented device-independent quantum random number generation experiments against classical attacks',
        '2018b': 'Liu Yang, Zhang Qiang et al. experimentally implemented a device-independent quantum random number generation experiments against quantum attacks',
        "cooperation": 'Cooperation and exchange',
        "achievements": 'Academic achievements based on our random number generation platform',
        "achievements_1": '1、In 2018, we experimentally realized device-independent quantum random number generation against classical and quantum attacks for the first time',
        "achievements_2": '2、In 2018, we participated in the Big Bell experiment, testing Bell\'s inequality using random numbers generated by human free will',
        "achievements_3": '3、In 2018, we tested Bell\'s inequality using starlight random numbers without detection and locality loopholes',
        "achievements_4": '4、In 2018, we realized high-speed device-independent quantum random number generation without a detection loophole',
        "achievements_5": '5、In 2019, we experimentally realized device-independent quantum random number expansion',
        "achievements_6": '6、In 2019, we experimentally realized device-independent quantum random number expansion against quantum side information',
        "historyContent1": ' 1. In 2018, Liu Yang represented the project team to participate in the Qcrypt2018 conference and gave a report entitled "Device-independent quantum random number generation".',
        "historyContent2": ' 2. In 2019, Liu Wenzhao represented the project team to participate in the CPS Fall Meeting and gave a report entitled "Expansion of Device Independent Quantum Random Number"',
        "historyContent3": ' 3. In 2019, Liu Wenzhao participated in the Single Photon Workshop2019 on behalf of the project team and gave a report entitled "Device independent randomness expansion against quantum side information".',
        "historyContent4": ' 4. In 2019, Liu Wenzhao participated in the Qcypt2020 meeting on behalf of the project team and gave a report entitled "Device-independent randomness expansion against quantum side information".',
        "historyContent5": ' 5. On October 16, 2020, the project team held a discussion on random number beacon and its application in Shanghai.',
        "historyContent6": ' 6. On January 22, 2021, the project team participated in the first "Quantum Random Numbers and Applications" conference of Shenzhen Southern University of Science and Technology.',

    }
}