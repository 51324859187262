export default {
    zh: {
        'title': '器件无关量子随机数信标脉冲及格式说明',
        'introduction': '随机数源模块每分钟发送一次格式为512位01序列的随机数脉冲。随机数信标模块接受随机数脉冲数据并完成不间断计数，进行密码学后处理并通过https://randomnessbeacon.com/beacon/1.0/chain/1/pulse/ N 依次对外广播。用户可一次性获取512bit器件无关量子随机数以及其相应的产生时间（UTC时间），其器件无关特性可根据贝尔不等式破坏值大小进行证明，其随机性可根据NIST检验结果进行证明。',
        'handle': '当前信标脉冲中随机数数值及下载',
        'sjs': '当前信标脉冲中随机数数值',
        'latest': '最新的',
        'first': '第一个',
        'previous': '上一个',
        'next': '下一个',
        'selectTime': 'UTC时间查询',
        'selectServer': '选择数据来源',
        'download': '下载',
        'bulkDownload': '批量下载',
        'ApiInterface': 'API接口调用',
        'information1': '当前信标脉冲信息(RSA)',
        'information2': '当前信标脉冲信息(PQC)',
        'certificatefile':'数字签名验签文件下载',
        'startTime': '开始时间',
        'endTime': '结束时间',
        'access': '获取与下载：更多下载请登录后查看',
        'url': '获取当前脉冲的超链接',
        'version': '当前信标脉冲格式的版本',
        'cipherSuite': '信标脉冲使用的密码套件（使用的加密和签名算法）',
        'period': '信标脉冲广播的周期',
        'certificateId': '用于检验信标脉冲签名的数字证书的HASH值',
        'chainIndex': '信标脉冲所属的HASH链的索引',
        'pulseIndex': '信标脉冲在HASH链中的索引值',
        'timestamp': '信标脉冲对外广播的UTC时间',
        'localRandomValue': '本地源随机数的HASH值',
        'externalSourceId': '外部随机数源的ID',
        'externalStatusCode': '外部随机数源的状态',
        'externalValue': '外部源随机数的数值',
        'previous1': '上一个信标脉冲的OUTPUT_VALUE属性值',
        'hour': '上一个信标脉冲所属小时的第一个信标脉冲的OUTPUT_VALUE属性值',
        'day': '上一个信标脉冲所属天的第一个信标脉冲的OUTPUT_VALUE属性值',
        'month': '上一个信标脉冲所属月的第一个信标脉冲的OUTPUT_VALUE属性值',
        'year': '上一个信标脉冲所属年的第一个信标脉冲的OUTPUT_VALUE属性值',
        'precommitmentValue': '下一个信标脉冲LOCAL_RANDOM_VALUE属性值的HASH值',
        'statusCode': 'HASH链在当前信标脉冲的状态',
        'type': '随机数源的原理类型',
        'CHSH': '器件无关量子随机数的CHSH不等式的违背值',
        'method': '器件无关量子随机数的产生理论方案',
        'nistTest': 'Nist标准对随机数的检测结果',
        'signatureValue': '对信标脉冲以上所有属性值的HASH值进行RSA-4096数字签名',
        'outputValue': '对信标脉冲以上所有属性值的HASH值',
        'signatureValuepqc': '对信标脉冲以上所有属性值的HASH值进行PQC数字签名',
        //'signatureValue': '对信标脉冲以上所有属性值的数字签名',
        //'outputValue': '对信标脉冲以上所有属性值的HASH值',
        // 'moredownland': '您筛选时间范围内无信标脉冲随机数数据，请重新选择时间',
        // 'downland': '当前账号未登录,请登录后操作',
        // 'null': '当前无随机数,不可下载',
        // 'error': '导出失败',
        // 'Notland': '不能下载随机数'
        'original': '随机数值',
        'token': '登录后返回的token',
        'pulseIndex': 'Beacon Pulse的索引，即当前脉冲链中的第几个脉冲',
        'addBjTime': '时间筛选 与 pulse_index二选一',
        'source': '1：济南2：上海',
    },
    en: {
        'title': 'Device-independent quantum random number beacon pulse and format description',
        'introduction': 'The random number source module sends a random number pulse by a 512-bit 0 and 1 sequence per minute. The random number beacon module accepts random number pulse data, completes uninterrupted counting, performs cryptographic post-processing, and broadcast in turn through hyperlinks "https://randomnessbeacon.com/beacon/1.0/chain/1/pulse/N".Users can obtain 512-bit device-independent quantum random numbers and the corresponding generation time (UTC time) at one time. The device-independent characteristics can be proved according to the damage value of Bell\'s inequality, and the randomness can be proved by the NIST statistical tests.',
        'handle': 'The random numbers of the current beacon pulse and download',
        'sjs': 'The random numbers of the current beacon pulse',
        'latest': 'latest',
        'first': 'First',
        'previous': 'Previous',
        'next': 'Next',
        'selectTime': 'UTC time：',
        'selectServer': 'data source：',
        'download': 'Current download',
        'bulkDownload': "Bulk download",
        'ApiInterface': 'API interface',
        'information1': 'The current pulse information(RSA)',
        'information2': 'The current pulse information(PQC)',
        'certificatefile': 'Certificate files',
        'startTime': 'startTime',
        'endTime': 'endTime',
        'access': 'Access and download: for more downloads, please log in and check',
        'url': ' a uniform resource locator that uniquely identifies the pulse',
        'version ': 'the version of the beacon format being used',
        'cipherSuite': 'the cipher suite (set of cryptographic algorithms) being used',
        'period': 'the number of milliseconds between the timestamps of this pulse and the expected subsequent pulse',
        'certificateId': 'the hash of the certificate that allows verifying the signature in the pulse;',
        'chainIndex': 'the chain index (integer identifier, starting at 1), of the chain to which the pulse belongs',
        'pulseIndex': 'the pulse index (integer identifier, starting at 1), conveying the order of generation of this pulse within its chain',
        'timestamp': 'the time (UTC) of pulse release by the Beacon Engine (the actual release time MAY be slightly larger, but SHALL NOT be smaller)',
        'localRandomValue': 'the hash() of two or more high-quality random bit sources; (For all practical purposes, it is expected to have full entropy; in rigor it MAY forfeit up to less than one bit of entropy, while indistinguishable from uniformly random.)',
        'externalSourceId': 'the hash() of a text description of the source of the external value, or an all-zeros byte string if there is no external value',
        'externalStatusCode': 'the status of the external value',
        'externalValue': 'the hash() of an external value, drawn from a verifiable external source from time to time, or an all-zeros string if there is no external value',
        'previous1': 'the OUTPUT_VALUE of the previous pulse',
        'hour': 'the OUTPUT_VALUE of the first pulse in the (UTC) hour of the previous pulse',
        'day': 'the OUTPUT_VALUE of the first pulse in the (UTC) day of the previous pulse',
        'month': 'the OUTPUT_VALUE of the first pulse in the (UTC) month of the previous pulse',
        'year': 'the OUTPUT_VALUE of the first pulse in the (UTC) year of the previous pulse',
        'precommitmentValue': 'the hash() of the next pulse’s LOCAL_RANDOM_VALUE',
        'statusCode': 'the status of the chain at this pulse',
        'type': 'the principle type of the random number source',
        'CHSH': 'the violation value of the CHSH inequality',
        'method': 'the theoretical scheme of device-independent quantum random number generation',
        'nistTest': 'the test result of the random number standard of Nist',
        'signatureValue': 'RSA-4096 signature on the hash() of all above fields',
        'signatureValuepqc': 'PQC signature on the hash() of all above fields',
        'outputValue': 'the hash() of all above fileds as a random output',
        //'signatureValue': 'a signature on all the above fields',
        //'outputValue': 'the hash() of all the above fields',
        // 'moredownland': 'You are filtering the random number data without beacon pulse in the time range, please select the time again',
        // 'downland': 'The current account is not logged in, please log in and operate',
        // 'null': 'There is no random number at present. It cannot be downloaded',
        // 'error': 'Export failed',
        // 'Notland': "Can't download random number"
        'original': 'Random numerical value',
        'token': 'Token returned after login',
        'pulseIndex': 'The index of Beacon Pulse, which pulse is in the current pulse chain',
        'addBjTime': 'Choose between time filtering and pulse_index',
        'source': '1. Jinan 2. Shanghai',
    }
}