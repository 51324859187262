<template>
  <div>
    <lunbo></lunbo>

    <!-- <div class="ms">
      <div class="title">{{ $t('index.pre_title1') }}<span style="color:#409EFF">{{ $t('index.after_title1') }}</span></div>
      <div class="content">
        {{$t('index.content')}}
      </div>
    </div> -->

    <div class="ms" style="margin-top: 50px;padding-bottom: 120px;">
      <div class="title">{{ $t('index.sjsInfo') }}</div>

      <div class="content sjs">
        <div style="display: flex;flex-direction:column;align-items:center;padding:25px 10px;">
          <span style="margin-bottom:15px;font-size:20px;color:#000;font-weight:bold;">OUTPUT_VALUE（RSA）</span>
          <span style="color: #0F88EB;margin-left: 0;font-size:17px;line-height:25px;">{{
        sjs || ""
      }}</span>
        </div>
        <div style="display: flex;flex-direction:column;align-items:center;padding:25px 10px;">
          <span style="margin-bottom:15px;font-size:20px;color:#000;font-weight:bold;">OUTPUT_VALUE（PQC）</span>
          <span style="color: #0F88EB;margin-left: 0;font-size:17px;line-height:25px;">{{
          sjss || ""
        }}</span>
        </div>
      </div>
    </div>

    <div class="gn">
      <div style="display:flex">
        <div style="flex:1" @click="toLink(item.url)" v-for="(item, index) in gnList" :key="index">
          <div class="hover-b" style="width:80%;margin:0 auto;background:white;padding:30px 10px">
            <img :src="item.imgUrl" style="width:45px;height:40px">
            <div class="small" style="margin:30px 0px;font-weight:bold">{{ $t('header.' + item.title) }}</div>
            <el-link type="primary" style="color:#409eff;font-size:14px">{{ $t('index.more') }} ></el-link>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top:30px;padding:0 20%">
      <h3>{{ $t('header.news') }}</h3>
      <p style="font-size:14px;margin:20px 0">{{ $t('index.newsIntroduction') }}</p>
      <div style="display:flex;margin-bottom:30px;text-align:left;cursor:pointer ">
        <div @click="toLink2(item(0).id)"
          style="margin-right:20px;height:180px;border-radius:8px;flex:1;position:relative">
          <img src="images/img1.png" style="width:100%;height:100%;opacity:0.4;" />
          <p style="position:absolute;top:10px;left:10px" :class="yuyan == 'zh' ? 'textindent' : ''">
            {{ item(0).title }}
            <br><br>
            <span style="font-size:14px"> {{ $com.timeFormat(parseInt(item(0).addtime + '000')) }}</span>
          </p>
        </div>
        <div style="margin-right:20px;flex:1;position:relative">
          <div @click="toLink2(item(1).id)" style="border-radius:8px;height:48%;position:relative">
            <img src="images/img3.png" style="width:100%;height:100%;opacity:0.4;position:relative" />
            <p style="position:absolute;top:10px;left:10px" :class="yuyan == 'zh' ? 'textindent' : ''">
              {{ item(1).title }}
              <br><br>
              <span style="font-size:14px"> {{
        item(1).addtime ? $com.timeFormat(parseInt(item(1).addtime + '000')) : ''
      }}</span>
            </p>
          </div>
          <p style="height:4%"></p>
          <div @click="toLink2(item(2).id)" style="border-radius:8px;height:48%;position:relative;">
            <img src="images/img2.png" style="width:100%;height:100%;opacity:0.4;position:relative" />
            <p style="position:absolute;top:10px;left:10px" :class="yuyan == 'zh' ? 'textindent' : ''">
              {{ item(2).title }}
              <br><br>
              <span style="font-size:14px"> {{
        item(2).addtime ? $com.timeFormat(parseInt(item(2).addtime + '000')) : ''
      }}</span>
            </p>
          </div>
        </div>
        <div style="border-radius:8px; background:#f3f4f6;flex:1">

          <!-- <div @click="toLink2(item(3).id)" style="height:50%">
            {{ item(3).title }}
            <br><br>
            <span
                style="font-size:14px"> {{
                item(3).addtime ? $com.timeFormat(parseInt(item(3).addtime + '000')) : ''
              }}</span>

          </div> -->

          <!-- <div @click="toLink2(item(4).id)" style="height:50%">
            {{ item(4).title }}
            <br><br>
            <span
                style="font-size:14px"> {{
                item(4).addtime ? $com.timeFormat(parseInt(item(4).addtime + '000')) : ''
              }}</span>
          </div> -->
          <!-- <div style="border-radius:8px; background:#f3f4f6;flex:1"> -->
          <div @click="toLink2(item(3).id)" style="height:100%;border-radius:8px;flex:1;position:relative">
            <img src="images/img1.png" style="width:100%;height:100%;opacity:0.4;" />
            <p style="position:absolute;top:10px;left:10px" :class="yuyan == 'zh' ? 'textindent' : ''">
              {{ item(3).title }}
              <br><br>
              <span style="font-size:14px"> {{ $com.timeFormat(parseInt(item(3).addtime + '000')) }}</span>
            </p>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sjs: '',
      sjss: '',
      list: [],
      gnList: [
        { title: 'projectOverview', imgUrl: 'images/summarize.png', url: '/summarize?index=1' },
        { title: 'accessDownload', imgUrl: 'images/download.png', url: '/acquire?index=2' },
        { title: 'safetyAnalysis', imgUrl: 'images/analyse.png', url: '/aqfx?index=3' },
        { title: 'about', imgUrl: 'images/history.png', url: '/history?index=4' }
      ],
      yuyan: localStorage.getItem('locale')
    }
  },
  mounted() {
    this.$bus.$on('changeLang', (lang) => {
      this.getList()
    })
    this.getList()
    this.getRandom();
  },
  computed: {
    item() {
      return (index) => {
        return this.list.length > index ? this.list[index] : {}
      }
    }
  },
  methods: {
    toLink2(id) {
      if (id) {
        this.$router.push({
          path: `/new-details?id=${id}`
        })
      }
    },
    getList() {
      this.$post('/index/article/recommend', { page: 1, limit: 5 }).then(
        (res) => {
          res.data.data.forEach(v => {
            v['y'] = this.$com.timeFormat(v.addtime).split("-")[0]
            v['m'] = this.$com.timeFormat(v.addtime).split("-")[1]
            v['d'] = this.$com.timeFormat(v.addtime).split("-")[2]
          })
          this.list = res.data.data
        })
    },
    getRandom() {
      this.$post('/index/random/find', { external_id: 1 }).then((res) => {
        if (res.data) {
          res.data.output_value && (this.sjs = res.data.output_value.toUpperCase())
          res.data.output_value_pqc && (this.sjss = res.data.output_value_pqc.toUpperCase())
        }
      })
    },
    toLink(url) {
      this.$router.push({
        path: url
      })
    }
  },
}
</script>
<style scoped>
.gn {
  background-color: #f3f4f6;
  /* height: 150px; */
  padding: 20px 22%;

}

.hover-b {
  border: 1px white solid;
  border-radius: 4px;
}

.hover-b:hover {
  border: #409eff 1px solid;
  cursor: pointer;
}

.ms {
  height: 150px;
  margin-top: 70px;
  background-color: white;
  padding: 0 20%;
}

.ms .title {
  font-size: 21px;
  font-weight: bold;

}

.ms .content {
  margin-top: 40px;
  font-size: 14px;
}

.ms .sjs {
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  color: #0F88EB;
  margin: 30px 0;
  /* overflow: hidden; */
  /* white-space: normal; */
  word-break: break-all;
  padding: 10px;
  /* flex-wrap: wrap; */
  text-align: left !important;
}

.ms .sjs>div {
  border: #0F88EB solid 1px;
  border-radius: 8px;
  width: 46%;
  box-shadow: 0 0 15px 3px #999;
}

.ms .sjs p:last-child {
  margin-top: 10px;
}

.ms .sjs p span {
  color: #000;
  display: inline-block;
  margin-left: 20px;
}

.textindent {
  text-indent: 2em;
}
</style>
