<template>
  <div class="boxShow">
    <div class="header_content">
      <div class="header_title">
        <div class="logo">
          <img
            src="../../public/images/logo.png"
            alt=""
            style=""
            @click="changeTitle1()"
          />
        </div>
        <div
          v-for="(item, index) in title"
          :key="index"
          @click="changeTitle(item)"
          style="font-size: 14px"
        >
          <p :class="navShow == index ? 'navActive' : ''">
            {{ $t("header." + item.name) }}
          </p>
        </div>
        <div class="userInfo">
          <el-dropdown @command="handleCommand">
            <el-tooltip
              v-show="showUserName"
              effect="dark"
              :content="userName"
              placement="right-end"
            >
              <p class="el-dropdown-link">
                <i class="el-icon-s-custom"></i>&nbsp;&nbsp;{{ userName }}
              </p>
            </el-tooltip>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="member">{{
                $t("header.member")
              }}</el-dropdown-item>
              <el-dropdown-item command="loginOut">{{
                $t("header.logout")
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <div class="loginOut">
            <p @click="loginOut">退出登录</p>
          </div>

          <div class="login_flex" v-show="showLogin">
            <p @click="changeLogin(0)" :class="loginShow == 0 ? 'Loginactive' : ''">
              {{ $t("header.login") }}
            </p>
            <p @click="changeLogin(1)" :class="loginShow == 1 ? 'Loginactive' : ''">
              {{ $t("header.register") }}
            </p>
          </div>

          <div class="login_flex" style="margin-left: 40px">
            <div
              @click="changeLangs()"
              v-show="languageShow"
              style="background: #409eff; padding: 5px 20px; border-radius: 20px"
            >
              <span style="color: #fff">中</span>
              <span style="color: #fff; margin: 0 5px">|</span>
              <span style="color: #004fa1">EN</span>
            </div>
            <div
              @click="changeLangs()"
              v-show="!languageShow"
              style="background: #409eff; padding: 5px 20px; border-radius: 20px"
            >
              <span style="color: #004fa1">中</span>
              <span style="color: #fff; margin: 0 5px">|</span>
              <span style="color: #fff">EN</span>
            </div>
            <!-- <el-dropdown @command="changeLangs">
              <span class="el-dropdown-link" style="display: inline-block; width: 80px;">
                {{ language }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="zh" >中文</el-dropdown-item>
                <el-dropdown-item command="en">English</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  watch: {
    "$route.path"() {
      this.navShow = this.$route.query.index || 0;
    },
    $route: {
      handler: function (route, oldVal) {
        if (route.query.do === "reg") {
          this.loginShow = 1;
        } else {
          this.loginShow = 0;
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      language: "中文",
      languageShow: true,
      loginShow: 0,
      title: [
        { id: 1, name: "home", url: "/" },
        { id: 2, name: "projectOverview", url: "/summarize?index=1" },
        { id: 3, name: "accessDownload", url: "/acquire?index=2" },
        { id: 7, name: "application", url: "/application?index=3" },
        { id: 4, name: "safetyAnalysis", url: "/aqfx?index=4" },
        { id: 5, name: "about", url: "/history?index=5" },
        // {id: 6, name: "news", url: "/news?index=5"},
      ],
      navShow: 0,
      userName: "",
      showLogin: true,
      showUserName: false,
    };
  },
  mounted() {
    this.navShow = this.$route.query.index || 0;
    if (this.$cookie.get("userName")) {
      let userName = this.$cookie.get("userName");
      this.userName = userName;
    }
    let userInfo = sessionStorage.getItem("userInfo");
    this.userInfo = JSON.parse(userInfo);
    if (this.userName) {
      this.showLogin = false;
      this.showUserName = true;
    }
    let lang = localStorage.getItem("locale");

    if (!lang) {
      localStorage.setItem("locale", "zh");
    }

    if (lang === "en") {
      // this.language = 'English'
      this.languageShow = false;
    } else {
      // this.language = '中文'
      this.languageShow = true;
    }
  },
  methods: {
    changeLangs() {
      let lang = localStorage.getItem("locale");
      if (lang === "en") {
        // this.language = 'English'
        this.languageShow = true;
        lang = "zh";
      } else if (lang === "zh") {
        // this.language = '中文'
        this.languageShow = false;
        lang = "en";
      } else {
        this.languageShow = false;
        lang = "en";
      }

      localStorage.setItem("locale", lang);
      // console.log(localStorage.getItem('locale'));
      this.$i18n.locale = lang;
      this.$bus.$emit("changeLang", lang);
    },
    handleCommand(command) {
      console.log(command);
      switch (command) {
        // 登出
        case "loginOut":
          this.loginOut();
          break;
        // 去个人中心
        case "member":
          this.goMember();
          break;
      }
    },
    // 去个人中心
    goMember() {
      this.$router.push("/member");
    },
    //退出登录
    loginOut() {
      this.$cookie.remove("userToken");
      this.$cookie.remove("userName");
      this.$router.push("/login");
      this.showLogin = true;
      this.showUserName = false;
      // location. reload()
    },
    changeLogin(index) {
      this.loginShow = index;
      if (index == 0) {
        this.$router.push({ path: "/login", query: { do: "login" } });
      } else if (index == 1) {
        this.$router.push({ path: "/login", query: { do: "reg" } });
      }
    },
    //切换导航栏
    changeTitle({ url }) {
      this.$router.push({
        path: url,
      });
    },
    changeTitle1() {
      this.$router.push({
        path: "/",
      });
    },
  },
};
</script>
<style scoped>
@import "../../public/css/header.css";

.header_title {
  cursor: pointer;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
