export default {
    zh: {
        address1: '中国科学技术大学上海研究院，量子物理与量子信息研究部',
        email: '联系邮箱：qrng@ustc.edu.cn',
        address2: '地址：中国上海市浦东新区秀浦路99号，200120',
        address3: '济南量子技术研究院',
        address4: '地址：山东省济南市高新区舜华路747号，250101',
        copy: '版权所有：济南量子技术研究院 | 中国科学技术大学上海研究院',
        technicalSupport: '技术支持：英网云 备案号：',
    },
    en: {
        address1: 'USTC Shanghai Institution for Advanced Studies, Division of Quantum Physics and Quantum Information',
        email: 'Email：qrng@ustc.edu.cn',
        address2: 'Address : No. 99 xiupu Road, Pudong New Area, Shanghai 200120, China Jinan Institute of Quantum Technology',
        address3: 'Jinan Institute of Quantum Technology',
        address4: 'Address: 747 Shunhua Road, High-tech Zone, Jinan, China 250101 Email: qrng@ustc.edu.cn',
        copy: 'Copyright: Jinan Institute of Quantum Technology | USTC Shanghai Institution for Advanced Studies, all rights reserved.',
        technicalSupport: 'Technical support:Ingnet  Record No：'
    }
}